import React from "react";
import './ConsultationPromotions.css';

const ConsultationPromotions = () => {
  return (
    <div>
      <p className="ConsultationPromotions">Consultation Promotions</p>
    </div>
  );
};

export { ConsultationPromotions };
